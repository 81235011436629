.collection-wrapper {
  display: inline-block;
  width: 100%;
  padding: 0.35rem;
  display: flex;
  justify-content: center;
}
.collection-wrapper .resource-item-inner .el-image__inner {
  width: 100% !important;
  height: 100% !important;
}
.collection-wrapper .resource-item-inner .resource-count {
  width: 0.74rem;
  height: 0.2rem;
  line-height: 0.2rem;
  font-size: 0.12rem;
  bottom: 0.5rem;
}
.collection-wrapper .desc-box .img-desc {
  right: 0.2rem;
  top: 0.2rem;
}
.collection-wrapper .desc-tip {
  width: 2.32rem !important;
  height: 1.74rem !important;
  top: 0.1rem !important;
  left: 0.1rem !important;
  padding: 0.15rem 0.14rem !important;
}
.collection-wrapper .desc-tip .img-close {
  right: 0.15rem !important;
  top: 0.12rem !important;
}
.collection-wrapper .resource-content {
  position: relative;
  padding-left: 0.4rem !important;
  padding-top: 0.05rem !important;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 auto;
}
.collection-wrapper .resource-content .scroll-loading {
  width: 100%;
  height: 50px;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -50px;
}
.collection-wrapper .resource-content .scroll-loading .img-scroll-loading {
  width: 50px;
  height: 50px;
}
.collection-wrapper .resource-content .desc-content {
  height: 96% !important;
  font-size: 0.12rem !important;
  width: 105% !important;
  line-height: 0.17rem !important;
  padding-top: 0.02rem;
  margin-top: 0.2rem !important;
}
.collection-wrapper .resource-content .desc-content::-webkit-scrollbar {
  display: block;
  width: 3px !important;
}
.collection-wrapper .resource-content::-webkit-scrollbar {
  height: 1px !important;
  display: block !important;
}
.collection-wrapper .no-data-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.collection-wrapper .no-data-content .img-no-data {
  margin-top: 0px;
  margin-right: 50px;
  width: 190px;
  height: 160px;
}
.resource-name {
  font-size: 0.15rem !important;
}
