































































































































.left-menu-wrapper {
  // margin-right: 22px;
  margin-right: 0.8rem;
  background: #fff;
  display: inline-block;
  vertical-align: top;
  // margin-bottom: 20px;
  // height: calc(100vh - 300px);
  // min-height: 500px;
  // max-height: 700px;
  // width: 240px;
  width: 2.8rem;
  height: 100%;
  background: #fff;
  border-radius: 40px;
  position: relative;
  .avatar-box {
    width: 100%;
    height: 1.84rem;
    // height: 190px;
    border-radius: 6px;
    text-align: center;
    background: #ade787;
    border-radius: 40px 40px 0 0;

    img,
    span {
      display: inline-block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      margin-top: 0.36rem;
    }
    p {
      color: #46851b;
      font-size: 0.16rem;
      padding: 0 20px;
    }
    &.avatar-box-gray {
      // img {
      //   margin-top: 150px;
      // }
      p {
        color: #6e6e6e;
      }
    }
  }
  .line {
    width: 230px;
    height: 1px;
    background: #f4d383;
    margin: 20px 5px;
  }
  .personal-menu-box {
    margin-bottom: 20px;
    margin-top: 30px;
    .personal-center-menu {
      padding: 0 27px;
      .menu-item {
        margin-top: 0.17rem;
        height: 0.4rem;
        line-height: 0.4rem;
        // padding-left: 38px;
        text-align: center;
        font-size: 0.16rem;
        color: #448816;
        cursor: pointer;
        border-radius: 7px;
        .img-box {
          display: inline-block;
          vertical-align: top;
          width: 25px;
          height: 25px;
          width: 0.25rem;
          height: 0.25rem;
          text-align: center;
          margin-right: 12px;
          margin-top: 7.5px;

          margin-right: 0.12rem;
          margin-top: 0.07rem;
          .img-menu {
            height: 25px;
            height: 0.25rem;
            display: inline-block;
            vertical-align: top;
          }
        }
        &.select-menu {
          // width: 187px;
          height: 0.4rem;
          background: #e3facd;
          // text-shadow: 0px 2px 1px #448816;
          box-shadow: 0px 2px 1px #eff8e8;
        }
        &:not(.select-menu):hover {
          background: #eff8e8;
          animation: none;
        }
      }
    }
  }
}
