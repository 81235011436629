.left-menu-wrapper {
  margin-right: 0.8rem;
  display: inline-block;
  vertical-align: top;
  width: 2.8rem;
  height: 100%;
  background: #fff;
  border-radius: 40px;
  position: relative;
}
.left-menu-wrapper .avatar-box {
  width: 100%;
  height: 1.84rem;
  border-radius: 6px;
  text-align: center;
  background: #ade787;
  border-radius: 40px 40px 0 0;
}
.left-menu-wrapper .avatar-box img,
.left-menu-wrapper .avatar-box span {
  display: inline-block;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  margin-top: 0.36rem;
}
.left-menu-wrapper .avatar-box p {
  color: #46851b;
  font-size: 0.16rem;
  padding: 0 20px;
}
.left-menu-wrapper .avatar-box.avatar-box-gray p {
  color: #6e6e6e;
}
.left-menu-wrapper .line {
  width: 230px;
  height: 1px;
  background: #f4d383;
  margin: 20px 5px;
}
.left-menu-wrapper .personal-menu-box {
  margin-bottom: 20px;
  margin-top: 30px;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu {
  padding: 0 27px;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu .menu-item {
  margin-top: 0.17rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  font-size: 0.16rem;
  color: #448816;
  cursor: pointer;
  border-radius: 7px;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu .menu-item .img-box {
  display: inline-block;
  vertical-align: top;
  width: 25px;
  height: 25px;
  width: 0.25rem;
  height: 0.25rem;
  text-align: center;
  margin-right: 12px;
  margin-top: 7.5px;
  margin-right: 0.12rem;
  margin-top: 0.07rem;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu .menu-item .img-box .img-menu {
  height: 25px;
  height: 0.25rem;
  display: inline-block;
  vertical-align: top;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu .menu-item.select-menu {
  height: 0.4rem;
  background: #e3facd;
  box-shadow: 0px 2px 1px #eff8e8;
}
.left-menu-wrapper .personal-menu-box .personal-center-menu .menu-item:not(.select-menu):hover {
  background: #eff8e8;
  animation: none;
}
