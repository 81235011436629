.basic-info-wrapper .self-el-form {
  padding-top: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.basic-info-wrapper .self-el-form .penIcon {
  width: 1vw;
  height: 1vw;
  position: absolute;
  top: 0.5vw;
  right: -2vw;
}
.basic-info-wrapper .self-el-form .penIcon img {
  width: 100%;
  height: 100%;
}
.basic-info-wrapper .self-el-form .el-form-item__label {
  min-width: 1.8rem;
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: right;
  text-align-last: right;
}
.basic-info-wrapper .self-el-form .el-input__icon {
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-right: 0.05rem;
}
.basic-info-wrapper .self-el-form .el-form-item {
  margin-top: 0.3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content {
  line-height: 0;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content .el-input {
  width: 3.68rem;
  height: 0.44rem;
  font-size: 0.14rem;
  line-height: 0.44rem;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content .el-input .el-input__inner {
  height: 0.4rem;
  line-height: 0.2rem;
  color: #579138;
}
.basic-info-wrapper .self-el-form .el-form-item .el-form-item__content .el-input .el-input__inner[disabled*=disabled] {
  color: #579138;
  opacity: 1;
  -webkit-text-fill-color: #579138;
}
.basic-info-wrapper .el-select .el-input .el-input__suffix {
  height: 0.4rem;
  line-height: 0.4rem;
}
.basic-info-wrapper .bottom-btns {
  width: 100%;
  height: 0.44rem;
}
.basic-info-wrapper .bottom-btns .el-button {
  width: 3.38rem;
  height: 0.44rem;
  margin-left: 3.61rem;
  margin-right: 2.87rem;
  font-size: 0.16rem;
  line-height: 0.44rem !important;
}
.basic-info-wrapper .self-el-form {
  padding-bottom: 0;
}
.el-input.is-disabled .el-input__inner {
  background: #c2eda6 !important;
}
