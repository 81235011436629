













































































@color-main: #03b2b2;
.personal-wrapper {
  width: 70vw;
  height: 74vh;
  margin: auto;
  position: relative;
  z-index: 15;
  margin-top: 4vh;
  .page-wrapper {
    // width: 1300px !important;
  }
  .personal-right-box {
    // width: 10.22rem;
    // height: 7.15rem;
    width: 50vw;
    height: 100%;
    display: inline-block;
    color: #448816;
    .login-btn-box {
      margin-top: 0.15rem;
    }
    .login-btn {
      width: 1.35rem;
      height: 0.3rem;
      line-height: 0.3rem;
      font-weight: normal;
      background: #67b035;
      color: #fff;
      border-radius: 10px;
      font-size: 0.16rem;
    }
  }
  .no-data-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-no-data {
      width: 2rem;
      height: 2rem;
      margin-top: 0;
      margin-right: 0.5rem;
    }
    .no-data-text {
      font-size: 0.16rem;
      margin-top: 0.1rem;
    }
  }
}
.personal_shade {
  width: 14.84rem;
  position: absolute;
  left: -0.22rem;
  bottom: -0.45rem;
}
