





































































.page-wrapper {
  width: 1300px;
  padding-top: 44px;
  // min-height: 700px !important;
  // width: calc(135vh) !important;
}
