


















































































































































































.change-pwd-wrapper {
  .self-el-form {
    // padding-top: 1.32rem;
    // padding-left: 2.54rem;
    // padding-left: 2.1rem;

    padding-top: 1.3rem;
    padding-left: 2.45rem;
    padding-right: 2.63rem;

    .el-form-item__label {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
    }
    .el-input__icon {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-right: 0.05rem;
    }
    .el-form-item {
      margin-top: 0.3rem;

      .el-form-item__content {
        line-height: 0 !important;

        .el-input {
          width: 3.58rem;
          height: 0.44rem;
          font-size: 0.14rem;
          .el-input__inner {
            height: 0.4rem;
          }
        }
      }
    }
  }
  .bottom-btns {
    width: 100%;
    height: 0.44rem;

    .el-button {
      width: 3.38rem;
      height: 0.44rem;
      margin-left: 3.61rem;
      margin-right: 2.87rem;
      font-size: 0.16rem;
      line-height: 0.44rem;
    }
  }
}
