


















































































































































































































































.basic-info-wrapper {
  .self-el-form {
    padding-top: .5rem;
    // padding-left: 2.45rem;
    // padding-right: 2.63rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;

    .penIcon {
      width: 1vw;
      height: 1vw;
      position: absolute;
      top: .5vw;
      right: -2vw;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-form-item__label {
      min-width: 1.8rem;
      // width: 1.1rem;
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: right;
      text-align-last: right;
    }

    .el-input__icon {
      font-size: 0.14rem;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-right: 0.05rem;
    }

    .el-form-item {
      margin-top: 0.3rem;
      margin-bottom: 0;
    display: flex;
    justify-content: space-around;
    // flex-direction: column;
    align-items: center;

      .el-form-item__content {
        line-height: 0;

        .el-input {
          width: 3.68rem;
          height: 0.44rem;
          font-size: 0.14rem;
          line-height: .44rem;

          .el-input__inner {
            height: 0.4rem;
            line-height: .2rem;
            color: #579138;
          }

          .el-input__inner[disabled*=disabled] {
            color: #579138;
            opacity: 1;
            -webkit-text-fill-color: #579138;
          }
        }
      }
    }
  }

  .el-select .el-input .el-input__suffix {
    height: 0.4rem;
    line-height: 0.4rem;
  }

  .bottom-btns {
    width: 100%;
    height: 0.44rem;

    .el-button {
      width: 3.38rem;
      height: 0.44rem;
      margin-left: 3.61rem;
      margin-right: 2.87rem;
      font-size: 0.16rem;
      line-height: 0.44rem !important;
    }
  }

  .self-el-form {
    padding-bottom: 0;
  }
}

.el-input.is-disabled .el-input__inner {
  background: #c2eda6 !important;
}

.basic-info-wrapper {}

// @media (max-width: 2560px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
// @media (max-width: 3840px) {
//   .el-select-dropdown__item {
//     font-size: 0.14rem !important;
//     height: 0.34rem !important;
//     line-height: 0.34rem !important;
//   }
// }
