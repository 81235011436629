.change-pwd-wrapper .self-el-form {
  padding-top: 1.3rem;
  padding-left: 2.45rem;
  padding-right: 2.63rem;
}
.change-pwd-wrapper .self-el-form .el-form-item__label {
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
}
.change-pwd-wrapper .self-el-form .el-input__icon {
  font-size: 0.14rem;
  height: 0.4rem;
  line-height: 0.4rem;
  margin-right: 0.05rem;
}
.change-pwd-wrapper .self-el-form .el-form-item {
  margin-top: 0.3rem;
}
.change-pwd-wrapper .self-el-form .el-form-item .el-form-item__content {
  line-height: 0 !important;
}
.change-pwd-wrapper .self-el-form .el-form-item .el-form-item__content .el-input {
  width: 3.58rem;
  height: 0.44rem;
  font-size: 0.14rem;
}
.change-pwd-wrapper .self-el-form .el-form-item .el-form-item__content .el-input .el-input__inner {
  height: 0.4rem;
}
.change-pwd-wrapper .bottom-btns {
  width: 100%;
  height: 0.44rem;
}
.change-pwd-wrapper .bottom-btns .el-button {
  width: 3.38rem;
  height: 0.44rem;
  margin-left: 3.61rem;
  margin-right: 2.87rem;
  font-size: 0.16rem;
  line-height: 0.44rem;
}
