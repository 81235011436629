/**
  调试全屏的样式可选
*/
.my-baby-wrapper {
  width: 100%;
  height: 100%;
  padding: 2vw;
}
.my-baby-wrapper .no-data-box {
  width: 100%;
  height: 100%;
}
.my-baby-wrapper .no-data-box .img-no-data {
  height: auto;
}
.my-baby-wrapper .painting-record-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 2vw;
  padding-left: 0.5vw;
}
.my-baby-wrapper .painting-record-container .crudeBorder {
  border: 3px solid #ade787;
}
.my-baby-wrapper .painting-record-container .fineBorder {
  border: 1px solid #ade787;
}
.my-baby-wrapper .painting-record-container .painting-record-item {
  width: 12.5vw;
  height: 12.5vw;
  margin-bottom: 2vw;
  overflow: hidden;
  border-radius: 30px;
  padding: 0;
}
.my-baby-wrapper .painting-record-container .painting-record-item .my_img {
  width: 100%;
  height: 100%;
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
}
.my-baby-wrapper .painting-record-container .painting-record-item .my_img .paintingFailText {
  width: 12.2vw;
  height: 12.5vw;
  position: relative;
}
.my-baby-wrapper .painting-record-container .painting-record-item .my_img .paintingFailText3 {
  background: url('../../assets/ui_ai-fe/绘制失败.jpg') no-repeat;
  background-size: 50%;
  background-position: center 30%;
}
.my-baby-wrapper .painting-record-container .painting-record-item .my_img .paintingFailText3::after {
  display: inline-block;
  width: 100%;
  text-align: center;
  content: '绘图失败';
  position: absolute;
  bottom: 3vw;
  left: 0;
  font-size: 1vw;
  color: #448816;
}
.my-baby-wrapper .painting-record-container .painting-record-item .my_img:hover {
  transform: scale(1.15);
}
.my-baby-wrapper .painting-record-container .painting-record-item:nth-of-type(3n+2) {
  margin-right: 2vw;
  margin-left: 1vw;
}
.my-baby-wrapper .painting-record-container .painting-record-item:nth-of-type(3n+1) {
  margin-right: 2vw;
}
.my-baby-wrapper .painting-record-container::-webkit-scrollbar {
  display: block;
  width: 5px;
}
.my-baby-wrapper .painting-record-container::-webkit-scrollbar-thumb {
  display: block;
  border-radius: 8px;
  background-color: #448816;
}
