.personal-wrapper {
  width: 70vw;
  height: 74vh;
  margin: auto;
  position: relative;
  z-index: 15;
  margin-top: 4vh;
}
.personal-wrapper .personal-right-box {
  width: 50vw;
  height: 100%;
  display: inline-block;
  color: #448816;
}
.personal-wrapper .personal-right-box .login-btn-box {
  margin-top: 0.15rem;
}
.personal-wrapper .personal-right-box .login-btn {
  width: 1.35rem;
  height: 0.3rem;
  line-height: 0.3rem;
  font-weight: normal;
  background: #67b035;
  color: #fff;
  border-radius: 10px;
  font-size: 0.16rem;
}
.personal-wrapper .no-data-box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.personal-wrapper .no-data-box .img-no-data {
  width: 2rem;
  height: 2rem;
  margin-top: 0;
  margin-right: 0.5rem;
}
.personal-wrapper .no-data-box .no-data-text {
  font-size: 0.16rem;
  margin-top: 0.1rem;
}
.personal_shade {
  width: 14.84rem;
  position: absolute;
  left: -0.22rem;
  bottom: -0.45rem;
}
